import React, { useState, useEffect, useContext } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Button from "../../components/Button"
import Divider from "../../components/Divider"
import InputBox, { INPUT_COLOUR } from "../../components/InputBox"
import PodcastSignupBar from "../../components/PodcastSignupBar"
import { GlobalDataContext } from "../../layouts"
import { ClipGrid } from "./episodes/{StrapiPodcastEpisodes.urlPath}"
import modalClose from "../../images/modal-close.svg"
import { orderBy } from "lodash"
import { MetaTagsContext } from "../../layouts"

const MISTRAL_TEST_LIST =
  "https://mistralvp.us18.list-manage.com/subscribe/post?u=a7f84395c9c1323f06833ebab&amp;id=71ff40a4e5"

const MicrophoneBackground = styled.div`
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(3, 4, 94, 0.35);

    @media (min-width: 768px) {
      left: 60%;
    }

    @media (min-width: 1536px) {
      left: 66.6666%;
    }
  }
`

const PodcastBadge = styled.a`
  &:hover {
    img {
      filter: brightness(1);
    }
  }
`

const Introduction = ({ data, handleButtonClick }) => {
  const sortedPodcastProviders = orderBy(data.podcastProviders, "order")

  return (
    <div className="relative overflow-hidden bg-mvp-light-blue">
      <MicrophoneBackground className="hidden md:block">
        <div
          className="absolute top-0 bottom-0 left-0 right-40% transform translate-x-full
        bg-cover bg-microphone bg-no-repeat
        md:bg-microphone-md
        xl:bg-microphone-xl
        2xl:right-1/3 
        "
          style={{
            backgroundImage: `url(${data.microphoneImage.url})`,
            filter: "grayscale(100%)",
          }}
        />
      </MicrophoneBackground>

      <div className="container">
        <div
          className="py-10
        xl:pt-40 xl:pb-52 xl:text-2-5xl flex flex-col
        "
        >
          <h1
            className="max-w-lg pb-5 text-36px font-light tracking-tight leading-tight
            lg:text-5xl lg:max-w-sm lg:pb-10
            xl:text-7xl xl:max-w-xl xl:pb-12
            2xl:max-w-3xl 2xl:text-104px"
          >
            {data.introductionTitle}
          </h1>

          <div
            className="max-w-750px text-18px 
            lg:text-xl
            xl:text-2-5xl"
          >
            <div className="xl:leading-tight">
              {data.introductionDescriptionLine1}
            </div>
            <div>{data.introductionDescriptionLine2}</div>
          </div>

          {data.listenCallToAction === "EMAIL" && (
            <button
              onClick={handleButtonClick}
              className="max-w-sm mt-6 py-2 rounded-full border border-mvp-dark-blue uppercase tracking-widest text-14px
            sm:mt-8
            md:max-w-xs
            lg:mt-10 lg:text-lg lg:max-w-sm
            xl:mt-10 xl:py-3 xl:px-14 xl:max-w-max
            hover:text-white hover:bg-mvp-dark-blue transition transition-colors duration-500 ease-in-out
        "
            >
              {data.introductionButtonLabel}
            </button>
          )}

          {data.listenCallToAction === "PODCAST_BUTTONS" && (
            <div
              className="grid grid-cols-2 gap-y-4 items-center justify-items-center w-full mt-8
              md:max-w-400px
              lg:max-w-500px lg:justify-items-start lg:gap-6 lg:mt-12
              "
            >
              {sortedPodcastProviders &&
                sortedPodcastProviders.map((provider) => (
                  <PodcastBadge
                    className="mt-2 lg:mt-4 cursor-pointer"
                    href={provider.url}
                    target="_blank"
                  >
                    <img
                      src={provider.badge && provider.badge.url}
                      className="filter grayscale w-165pixel lg:w-192px"
                    />
                  </PodcastBadge>
                ))}
            </div>
          )}
        </div>
      </div>

      <MicrophoneBackground className="relative h-64 md:hidden">
        <div
          className="h-full bg-auto bg-microphone"
          style={{
            backgroundImage: `url(${data.microphoneImage.formats.medium.url})`,
            filter: "grayscale(100%)",
          }}
        />
      </MicrophoneBackground>
    </div>
  )
}

const NewestEpisode = ({ episode, data }) => {
  const [clip, setClip] = useState(episode.podcastEpisodeClips[0])
  const [currentClipArrayIndex, setCurrentClipArrayIndex] = useState(0)

  const globalData = React.useContext(GlobalDataContext)

  const isFirstClip =
    episode.podcastEpisodeClips[0].clipNumber === clip.clipNumber
  const isLastClip =
    episode.podcastEpisodeClips[episode.podcastEpisodeClips.length - 1]
      .clipNumber === clip.clipNumber

  const goToPreviousClip = () => {
    const newIndex = currentClipArrayIndex - 1
    const previousClip = episode.podcastEpisodeClips[newIndex]

    setClip(previousClip)
    setCurrentClipArrayIndex(newIndex)
  }

  const goToNextClip = () => {
    const newIndex = currentClipArrayIndex + 1
    const nextClip = episode.podcastEpisodeClips[newIndex]

    setClip(nextClip)
    setCurrentClipArrayIndex(newIndex)
  }
  return (
    <div className="bg-white">
      <div className="container pt-10 lg:pt-24 pb-6">
        <div className="grid grid-cols-1 lg:grid-cols-3">
          <div className="text-36px font-semibold lg:col-span-1 lg:text-4-5xl">
            {data.newestEpisodeTitle}
          </div>

          <div className="lg:col-span-2">
            <Link
              to={`/the-product-market-fit-show/episodes/${episode.urlPath}`}
              className="text-36px lg:text-4-5xl font-light hover:underline"
            >{`E${episode.episodeNumber}. ${episode.title}`}</Link>
            <div className="lg:text-2xl">{`${episode.founderName}, ${episode.companyName}`}</div>

            <div className="flex mt-4 lg:mt-6">
              <Button
                page={`/the-product-market-fit-show/episodes/${episode.urlPath}#transcript`}
                colour="blue"
                label={data.newestEpisodeTranscriptButton}
                className="w-48 mr-4 text-center no-x-padding"
              />
            </div>
          </div>

          <div className="lg:col-span-3">
            <div className="relative mt-10 mb-6 pt-videos">
              <iframe
                src={`https://player.vimeo.com/video/${clip.vimeoVideoId}?h=63e72e33f7&title=0&byline=0&portrait=0`}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>

            <div className="flex justify-between">
              <span>
                {!isFirstClip && (
                  <span
                    className="text-15px text-mvp-dark-blue tracking-widest uppercase cursor-pointer border-b-4 border-transparent hover:border-mvp-dark-blue"
                    onClick={goToPreviousClip}
                  >
                    &lt; {globalData.podcastEpisodePreviousClip}
                  </span>
                )}
              </span>

              <span>
                {!isLastClip && (
                  <span
                    className="text-15px text-mvp-dark-blue tracking-widest uppercase cursor-pointer border-b-4 border-transparent hover:border-mvp-dark-blue"
                    onClick={goToNextClip}
                  >
                    {globalData.podcastEpisodeNextClip} &gt;
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const FilterButton = ({ label, isActive, className, handleOnClick }) => (
  <button
    onClick={handleOnClick}
    className={`
        inline-block
        py-2 px-4
        border border-mvp-dark-blue rounded-full
        uppercase tracking-widest text-15px
        transition transition-colors duration-500 ease-in-out
        hover:text-white hover:bg-mvp-dark-blue 
        ${isActive ? "text-white bg-mvp-dark-blue" : "cursor-pointer"}
        ${className}`}
  >
    {label}
  </button>
)

const EpisodeList = ({ data, episodes }) => {
  const globalData = React.useContext(GlobalDataContext)

  const [episodeList, setEpisodeList] = useState(episodes)
  const [firstThreeEpisodes, setFirstThreeEpisodes] = useState([])
  const [remainingEpisodes, setRemainingEpisodes] = useState([])
  const [visibleEpisodes, setVisibleEpisodes] = useState([])
  const [isFilterNewest, setIsFilterNewest] = useState(true)
  const [isFilterOldest, setIsFilterOldest] = useState(false)
  const [isFilterCompany, setIsFilterCompany] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const orderByNewest = () => {
    const list = orderBy(episodeList, ["episodeNumber"], "desc")
    setEpisodeList(list)
    setIsFilterNewest(true)
    setIsFilterOldest(false)
    setIsFilterCompany(false)
    setSelectedIndex(0)
  }

  const orderByOldest = () => {
    const list = orderBy(episodeList, ["episodeNumber"], "asc")
    setEpisodeList(list)
    setIsFilterNewest(false)
    setIsFilterOldest(true)
    setIsFilterCompany(false)
    setSelectedIndex(0)
  }

  const orderByCompany = () => {
    const list = orderBy(episodeList, ["companyName"], "asc")
    setEpisodeList(list)
    setIsFilterNewest(false)
    setIsFilterOldest(false)
    setIsFilterCompany(true)
    setSelectedIndex(0)
  }

  useEffect(() => {
    setFirstThreeEpisodes(episodeList.slice(0, 3))
    setRemainingEpisodes(episodeList.slice(3))
    setVisibleEpisodes(episodeList.slice(3, 6))
  }, [episodeList])

  const EpisodeBreadcrumbSelection = () => {
    const adjustEpisodeSet = (index) => {
      setSelectedIndex(index)

      const slicePositionStart = index * 3
      const slicePositionEnd = slicePositionStart + 3

      setVisibleEpisodes(
        remainingEpisodes.slice(slicePositionStart, slicePositionEnd)
      )
    }

    const breadcrumbCount = Math.ceil(remainingEpisodes.length / 3)
    const numberOfBreadcrumbs = new Array(breadcrumbCount).fill(0)
    const isFirstIndex = selectedIndex === 0
    const isLastIndex = selectedIndex === numberOfBreadcrumbs.length - 1

    const breadcrumbs = numberOfBreadcrumbs.map((crumb, index) => (
      <span
        className={`inline-block px-2 border-b-4 border-transparent
          ${
            selectedIndex == index
              ? "cursor-default border-mvp-dark-blue"
              : "cursor-pointer hover:border-mvp-dark-blue"
          }
          `}
        onClick={() => adjustEpisodeSet(index)}
      >
        {index + 1}
      </span>
    ))

    return (
      <div className="flex justify-between py-8">
        <div className="col-span-2 text-center uppercase tracking-widest text-sm ">
          <span className={`${!isFirstIndex ? "" : "text-mvp-med-grey"}`}>
            &lt;{" "}
          </span>
          <span
            className={`${
              !isFirstIndex
                ? "cursor-pointer hover:border-b-4 hover:border-mvp-dark-blue"
                : "text-mvp-med-grey"
            }`}
            onClick={() =>
              !isFirstIndex ? adjustEpisodeSet(selectedIndex - 1) : null
            }
          >
            {data.previousEpisodes}
          </span>
        </div>
        <div className="col-span-2 text-center uppercase tracking-widest text-sm">
          {breadcrumbs}
        </div>
        <div
          className="col-span-2 text-center uppercase tracking-widest text-sm"
          onClick={() => adjustEpisodeSet(selectedIndex + 1)}
        >
          <span
            className={`${
              !isLastIndex
                ? "cursor-pointer hover:border-b-4 hover:border-mvp-dark-blue"
                : "text-mvp-med-grey"
            }`}
            onClick={() =>
              !isLastIndex ? adjustEpisodeSet(selectedIndex + 1) : null
            }
          >
            {data.nextEpisodes}
          </span>{" "}
          <span className={`${!isLastIndex ? "" : "text-mvp-med-grey"}`}>
            &gt;
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-white">
      <div className="container pb-16">
        <div>
          <FilterButton
            label={data.filterButtonNewest}
            isActive={isFilterNewest}
            className="mr-4"
            handleOnClick={orderByNewest}
          />

          <FilterButton
            label={data.filterButtonOldest}
            isActive={isFilterOldest}
            className="mr-4 mt-4"
            handleOnClick={orderByOldest}
          />

          <FilterButton
            label={data.filterButtonCompany}
            isActive={isFilterCompany}
            className="mt-4"
            handleOnClick={orderByCompany}
          />
        </div>

        <ClipGrid className="pt-16" episodes={firstThreeEpisodes} />
      </div>

      {data && data.isSecondSignupBarVisible && <PodcastSignupBar />}

      {!!remainingEpisodes.length && (
        <div
          className={`container pb-16 ${
            data.isSecondSignupBarVisible ? "pt-16" : ""
          }`}
        >
          <ClipGrid episodes={visibleEpisodes} />

          {remainingEpisodes.length > 3 && (
            <>
              <EpisodeBreadcrumbSelection />
              <Divider className="mb-16" />
            </>
          )}
        </div>
      )}
    </div>
  )
}

const SignupModal = ({ handleCloseModal, popupText }) => {
  const globalData = React.useContext(GlobalDataContext)

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const handleSubmit = () => {
    setIsFormSubmitted(true)
  }

  return (
    <div className="z-50 fixed top-0 bottom-0 left-0 right-0 flex items-center bg-mvp-dark-blue-25">
      <div className="container h-full sm:h-auto py-1rem">
        <div
          className="relative max-w-screen-md flex flex-col justify-center h-full items-center mx-auto p-1rem bg-white
          sm:py-14
        "
        >
          <img
            src={modalClose}
            className="absolute right-0 top-0 px-4 py-4 cursor-pointer"
            onClick={handleCloseModal}
          />

          {!isFormSubmitted && (
            <div className="font-light text-36px pb-6 text-center">
              {popupText}
            </div>
          )}

          <InputBox
            colour={INPUT_COLOUR.DARK_BLUE}
            buttonLabel={globalData.podcastSignupButton}
            placeholderLabel={globalData.podcastSignupInputPlaceholder}
            className="w-full justify-center text-center xl:transform xl:translate-x-6"
            buttonClasses="w-300px max-w-300px mx-auto"
            mailchimpList={MISTRAL_TEST_LIST}
            signupSuccessMessage={globalData.podcastSignupSuccess}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  )
}

const ThePmfShow = ({ data }) => {
  let newestEpisode
  let podcastEpisodes

  const podcastPageData = data.strapiThePmfShow
  const podcastEpisodeData = data.allStrapiPodcastEpisodes.nodes
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalText, setModalText] = useState(podcastPageData.popupText)

  const { setMetaTags } = useContext(MetaTagsContext)
  setMetaTags(data.strapiThePmfShow.seo)

  const sortedEpisodes = orderBy(podcastEpisodeData, ["episodeNumber"], "desc")
  const duplicatedEpisodes = [...sortedEpisodes]

  duplicatedEpisodes.shift()
  newestEpisode = sortedEpisodes[0]
  podcastEpisodes = duplicatedEpisodes

  const handleButtonClick = () => {
    setIsModalVisible(true)
  }

  const handleCloseModal = () => {
    setIsModalVisible(false)
    setModalText(podcastPageData.popupText)
  }

  useEffect(() => {
    if (podcastPageData.onPageLoadPopupIsEnabled) {
      setModalText(podcastPageData.onPageLoadPopupText)

      setTimeout(() => {
        setIsModalVisible(true)
      }, podcastPageData.onPageLoadPopupDelaySeconds * 1000)
    }
  }, [])

  return (
    <>
      {isModalVisible && (
        <SignupModal
          handleCloseModal={handleCloseModal}
          popupText={modalText}
        />
      )}

      {podcastPageData && (
        <Introduction
          data={podcastPageData}
          handleButtonClick={handleButtonClick}
        />
      )}
      <NewestEpisode data={podcastPageData} episode={newestEpisode} />

      {podcastPageData && podcastPageData.isFirstSignupBarVisible && (
        <PodcastSignupBar className="mb-8" />
      )}

      {podcastEpisodes && podcastPageData && (
        <EpisodeList data={podcastPageData} episodes={podcastEpisodes} />
      )}
    </>
  )
}

export const query = graphql`
  {
    strapiThePmfShow {
      previousEpisodes
      popupText
      nextEpisodes
      newestEpisodeTranscriptButton
      newestEpisodeTitle
      isSecondSignupBarVisible
      isFirstSignupBarVisible
      introductionTitle
      introductionDescriptionLine2
      introductionDescriptionLine1
      introductionButtonLabel
      filterButtonOldest
      filterButtonNewest
      filterButtonCompany

      listenCallToAction

      onPageLoadPopupIsEnabled
      onPageLoadPopupText
      onPageLoadPopupDelaySeconds
      seo {
        title
        description
      }
      microphoneImage {
        url
        formats {
          small {
            url
          }
          medium {
            url
          }
          large {
            url
          }
        }
        ext
      }

      podcastProviders {
        url
        order
        badge {
          url
        }
      }
    }
    allStrapiPodcastEpisodes {
      nodes {
        companyName
        episodeNumber
        founderName
        title
        urlPath
        clipThumbnail {
          thumbnail {
            url
          }
        }
        podcastEpisodeClips {
          clipNumber
          podcastEpisode
          title
          vimeoVideoId
        }
      }
    }
  }
`

export default ThePmfShow
